import { useEffect, useRef, useState } from "react";
import { filterOptions } from "./FilterBy/FilterByOptions";
import {
    AdvancedFilterComponent
} from '../../../../../types/advancedFilters';
import { useFilterDropDownStore } from "../../../../../store/filterDropDownStore";
import { useAdvancedFilterStore } from "../../../../../store/advancedFiltersStore";
import LogicDropdown from "./LogicDropdown";
import ValueInputGroup from "./AFInput";
import AdvancedFilterDropDown from "../../../AdvancedFilterDropDown";
import FilterByDropdown from "./FilterBy/FilterByCombobox";
import {crossDataFiltering} from '../../../../../utils/cross_filtering_in_dropdowns'
import { urlSearchId } from "../../../../../utils/url_search_id";

type AdvancedFilterProps = {
    removeFilter: () => void;
    filter: AdvancedFilterComponent;
    updateFilter: (filter: AdvancedFilterComponent) => void;
    id?: number;
}

export default function AdvancedFilter({
    removeFilter,
    filter,
    updateFilter,
    id,
}: Readonly<AdvancedFilterProps>) {
    console.log(filter)
    const [selectedFilterOption, setSelectedFilterOption] = useState(() => {
        const matchingOption = filterOptions.find(opt => opt.value === filter.field);
        return matchingOption || filterOptions[0];
    });
    const [operator, setOperator] = useState(filter.operator || '');
    const [filterValue, setFilterValue] = useState<number | string | string[]>(filter.value || '');
    const [selectedLabel,setSelectedLabel] =useState("")
    const [showListDropdown, setShowListDropdown]=useState(false);
    const [dropdownListData,setDropdownListData] =useState<string[]>([]);
    const [dropdownAllListData,setDropdownAllListData] =useState([]);
    const [allFilterData,setAllFilterData] = useState<string[]>([]);
    const [selectedOptions, setSelectedOptions] =useState<string[]>([]);

    const startIndex =  useFilterDropDownStore(state => state.startIndex);
    const endIndex =  useFilterDropDownStore(state => state.endIndex);
    const allDropdownListData = useAdvancedFilterStore(state => state.allDropdownListData)
    const {crossFilterObjectFiltering } = crossDataFiltering();
    const setCrossFilterSelection =useFilterDropDownStore(state => state.setCrossFilterSelection);
    const prevFilterRef = useRef(filter);
    const dropDownFields =[ 
        "study_description",
        "series_description",
        "body_part_examined",
        "manufacturer_model_name",
        "imputed_ethnicity",
        "imputed_race",
        "institution",
        'state',
    ]
        const updateStatedropdownList=(newList:any, newFilter :any)=>{
        const updatedStateDataList:any = newFilter?.options && newFilter?.options.map((target:any) => {
            
            const matchingSources = newList && newList.filter((source:any) =>
                source.grouping_field.includes(target.value)
            );
            
            const totalCount = matchingSources && matchingSources.reduce(
                (sum :number, source:any) => sum + source.count_of_field,
                0
            );
            
            return {
                ...target,
                grouping_field: target.label,
                count_of_field: totalCount > 0 ? totalCount : 0,
            };
        });
        setDropdownListData(updatedStateDataList)
        setDropdownAllListData(updatedStateDataList);
        setAllFilterData(updatedStateDataList);
    }

    const handleFilterChange = (newFilter: typeof selectedFilterOption) => {
        setSelectedFilterOption(newFilter);
        setSelectedLabel(newFilter.name)
        if (dropDownFields.includes(newFilter.value)){
            setShowListDropdown(true);
            const newSelected:any = newFilter.id === 20 ? "institution": newFilter.value;
            const newList :any = allDropdownListData[newSelected];
            if(newFilter.id === 20){
                updateStatedropdownList(newList, newFilter)
            }else{
                setDropdownAllListData(newList);
                setAllFilterData(newList);
                setDropdownListData(newList?.slice(startIndex,endIndex));
            } 
            setSelectedOptions([]);
        }else{
            setShowListDropdown(false);
        }
        // If the operator for the new field is different, update it immediately
        if (!newFilter.operatorOptions.includes(operator)) {
            setOperator(newFilter.operatorOptions[0]);
        }
    }

    useEffect(() => {
        if(!showListDropdown){
            updateFilter({
                ...filter,
                field: selectedFilterOption.value,
                operator,
                value: filterValue
            });
        }else{
            let checkedItems = dropdownListData
            .filter((item:any) => item.checked)
            .map((item:any) => item.value);
            if(checkedItems.length === 0 && selectedOptions.length === 0 && filter.value === ''){
                prevFilterRef.current.value = selectedOptions;
            }
            updateFilter({
                ...filter,
                field: selectedFilterOption.value,
                operator,
                value: urlSearchId ? filterValue : selectedOptions.length > 0 ? selectedOptions : prevFilterRef.current.value
            });

        }
    }, [selectedFilterOption, operator, filterValue,selectedOptions,showListDropdown]);

    useEffect(()=>{
        if(dropDownFields.includes(filter.field)) {
            setShowListDropdown(true);
            const newSelected:any = selectedFilterOption.value === 'state' ? "institution" : filter.field;
            const newList :any = allDropdownListData[newSelected];
            if(selectedFilterOption.value === "state"){
                updateStatedropdownList(newList, selectedFilterOption)
            }else{
                setDropdownListData(newList?.slice(startIndex,endIndex));
                setDropdownAllListData(newList);
                setAllFilterData(newList);
            }
            setSelectedLabel(selectedFilterOption.name);
            if(Array.isArray(filter.value) && filter.value.length>0){
                setSelectedOptions(filter.value) ;
            }
        }
    },[allDropdownListData])

    return (
        <div className="space-y-2 flex flex-col bg-slate-100 rounded shadow py-2 px-2 border border-gray-300">
            <div>
                <FilterByDropdown id={id} filter={selectedFilterOption} setFilterBy={handleFilterChange}/>
                {showListDropdown && 
                <AdvancedFilterDropDown label={selectedLabel} field={selectedFilterOption.value}
                    advancedFilterListData={dropdownListData}
                    setDropdownListData={setDropdownListData}
                    allData = {dropdownAllListData}
                    allFilterData ={allFilterData}
                    setAllFilterData={setAllFilterData}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    filterId={selectedFilterOption.id}
                    />
                }
            </div>
            {!showListDropdown && 
            <div className="flex flex-row gap-x-2">
                <LogicDropdown id={id} selectedFilter={selectedFilterOption} operator={operator} setLogic={setOperator}/>
                <ValueInputGroup id={id} setFilterValue={setFilterValue} filterValue={filterValue} filterType={selectedFilterOption.type} options={selectedFilterOption.options} defaultFilterValue={filterValue} />
            </div>
            }
            {!showListDropdown && filterValue.toString().length === 0 && <p className="text-xs text-red-500 italic">Advanced filter fields cannot be empty</p>}
            <div className="text-right px-4">
                <button
                    onClick={() => {
                        removeFilter();
                        if(dropDownFields.includes(selectedFilterOption.value)){
                            setSelectedOptions([]);
                            const updatedCrossFilter = crossFilterObjectFiltering(selectedFilterOption.value);
                            setCrossFilterSelection(updatedCrossFilter);
                        }
                        dropdownListData?.forEach((option: any) => (option.isChecked = false));
                        setDropdownListData(dropdownListData?.slice(0, 499));
                    }}
                    className="text-red-600 hover:text-red-800 font-semibold text-sm"
                >
                    Remove
                </button>
            </div>
        </div>
    )
}