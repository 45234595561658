import { useFilterStore } from "../store/filterStore";
import { usePatientStore } from "../store/patientStore";

export function useUpdateFilter() {
  const setSexSelections = useFilterStore((state) => state.setSexSelections);
  const setSliceThicknessSelections = useFilterStore((state) => state.setSliceThicknessSelections);
  const setSelectedOptions = useFilterStore((state) => state.setSelectedOptions);
  const setAgeSelections = useFilterStore((state) => state.setAgeSelections);
  const setUsOnly = useFilterStore((state) => state.setUsOnly);
  const setCrossFilterOnly = useFilterStore((state) => state.setCrossFilterOnly);
  const setTimeBetween = usePatientStore((state) => state.setTimeBetween);
  const setStudyDateRange = useFilterStore((state) => state.setStudyDateRange);
  const setStudyAddedDateRange = useFilterStore((state) => state.setStudyAddedDateRange);
  const setModalitySelections = useFilterStore((state) => state.setModalitySelections);
  const setManufacturerSelections = useFilterStore((state) => state.setManufacturerSelections);

  const updateFilter = (filterObject: any) => {
    if (filterObject.sex) {
      setSexSelections(filterObject.sex);
    }
    if (filterObject.slice_thickness) {
      setSliceThicknessSelections([filterObject.slice_thickness.min, filterObject.slice_thickness.max]);
    }
    if (filterObject.modality) {
      setModalitySelections(filterObject.modality.filter((item: string)=> item!== "null" && item !== "UNKNOWN"));
    }
    if (filterObject.age) {
      setAgeSelections([filterObject.age.min, filterObject.age.max]);
    }
    if (filterObject.us_only) {
      setUsOnly(filterObject.us_only);
    }
    if (filterObject.manufacturer) {
      setManufacturerSelections(filterObject.manufacturer.filter((item: string)=> item!== "null" && item !== "UNKNOWN"));
    }
    if (filterObject.manufacturer_model_name) {
      setSelectedOptions("manufacturer_model_name", filterObject.manufacturer_model_name);
    }
    if (filterObject.study_description) {
      setSelectedOptions("study_description", filterObject.study_description);
    }
    if (filterObject.body_part_examined) {
      setSelectedOptions("body_part_examined", filterObject.body_part_examined);
    }
    if (filterObject.series_description) {
      setSelectedOptions("series_description", filterObject.series_description);
    }
    if (filterObject.institution) {
      setSelectedOptions("institution", filterObject.institution);
    }
    if (filterObject.cross_filter) {
      setCrossFilterOnly(filterObject.cross_filter);
    }
    if (filterObject.date) {
      setStudyDateRange(filterObject.date);
    }
    if (filterObject.date_added) {
      setStudyAddedDateRange(filterObject.date_added);
    }
    if (filterObject.timeBetween) {
      setTimeBetween(filterObject.timeBetween);
    }
    if (filterObject.imputed_ethnicity) {
      setSelectedOptions("imputed_ethnicity", filterObject.imputed_ethnicity);
    }
    if (filterObject.imputed_race) {
      setSelectedOptions("imputed_race", filterObject.imputed_race);
    }
    if(filterObject.state){
      setSelectedOptions("state", filterObject.state);
    }
  };

  return { updateFilter };
}
