type FilterOptionType = {
    id: number;
    name: string;
    value: string;
    operatorOptions: string[];
    type: string;
    options?: any[]
}
export const filterOptions: FilterOptionType[] = [
    { 
        id: 1, 
        name: 'Body Part Examined', 
        value: 'body_part_examined', 
        operatorOptions: ['contains'], 
        type: 'string' 
    },  
    { 
        id: 2, 
        name: 'Contrast Bolus Agent', 
        value: 'contrast_bolus_agent', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'], 
        type: 'string' 
    },
    { 
        id: 3, 
        name: 'Ethnic Group', 
        value: 'ethnic_group', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'], 
        type: 'string' 
    },
    { 
        id: 4, 
        name: 'KVP', 
        value: 'kvp', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number' 
    },
    { 
        id: 5, 
        name: 'Laterality', 
        value: 'laterality', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'], 
        type: 'string' 
    },
    { 
        id: 6, 
        name: 'Magnetic Field Strength', 
        value: 'magnetic_field_strength', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number' 
    },
    { 
        id: 7, 
        name: 'Manufacturer Model Name', 
        value: 'manufacturer_model_name', 
        operatorOptions: ['contains'], 
        type: 'string' 
    },
    { 
        id: 8, 
        name: 'Patient Weight', 
        value: 'patient_weight', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number' 
    },
    { 
        id: 9, 
        name: 'Patient Size', 
        value: 'patient_size', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number' 
    },
    { 
        id: 10, 
        name: 'Photometric Interpretation', 
        value: 'Photometric_interpretation', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'], 
        type: 'string' 
    },
    { 
        id: 11, 
        name: 'Protocol Name', 
        value: 'protocol_name', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'], 
        type: 'string' 
    },
    { 
        id: 12, 
        name: 'Series Description', 
        value: 'series_description', 
        operatorOptions: ['contains'], 
        type: 'string' 
    },
    { 
        id: 13, 
        name: 'Slice Thickness', 
        value: 'slice_thickness', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number' 
    },
    { 
        id: 14, 
        name: 'Study Description', 
        value: 'study_description', 
        operatorOptions: ['contains'], 
        type: 'string' 
    },
    { 
        id: 15, 
        name: 'Number of Slices', 
        value: 'number_of_frames', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number' 
    },
    { 
        id: 16, 
        name: 'PatientID', 
        value: 'patient_id', 
        operatorOptions: ['contains', 'does not contain'], 
        type: 'string' 
    },
    { 
        id: 17, 
        name: 'AccessionNumber', 
        value: 'accession_number', 
        operatorOptions: ['contains', 'does not contain'], 
        type: 'string' 
    },
    { 
        id: 18, 
        name: 'StudyDate', 
        value: 'study_date', 
        operatorOptions: ['before', 'after'], 
        type: 'date' 
    },
    { 
        id: 19, 
        name: 'IngestionDate', 
        value: 'date_added', 
        operatorOptions: ['before', 'after'], 
        type: 'date' 
    },
    { 
        id: 20, 
        name: 'State', 
        value: 'state', 
        operatorOptions: ['contains'], 
        type: 'select',
        options: [{
            label: 'North Carolina', value: 'thryothor', checked: false
        },{
            label: 'Missouri', value: 'xenops', checked: false
        },{
            label: 'New York', value: 'tachyeres', checked: false
        },{
            label: 'Indiana', value: 'peregrine', checked: false
        },{
            label: 'Texas', value: 'quelea', checked: false
        }]
    },
    { 
        id: 21, 
        name: 'Imputed Ethnicity', 
        value: 'imputed_ethnicity', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'],
        type: 'string' 
    },
    { 
        id: 22, 
        name: 'Imputed race', 
        value: 'imputed_race', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'],
        type: 'string' 
    },
    { 
        id: 23, 
        name: 'Diffusion BValue', 
        value: 'diffusion_bvalue', 
        operatorOptions: ['less than', 'greater than', 'equals'], 
        type: 'number'
    },
    { 
        id: 24, 
        name: 'Sequence Name', 
        value: 'sequence_name', 
        operatorOptions: ['contains', 'does not contain', 'equals', 'not equal to'],
        type: 'string' 
    },
    { 
        id: 25, 
        name: 'Institution', 
        value: 'institution', 
        operatorOptions: ['contains'], 
        type: 'string' 
    },  
].sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
        return nameA !== nameB ? (nameA < nameB ? -1 : 1) : 0;
});